import Button from '@veneer/core/dist/scripts/button'
import styled, { css } from 'styled-components'
import { MOBILE_CONTAINER } from '../../hooks'

export const StyledReturnButton = styled(Button)`
  width: fit-content;

  ${(props) => {
    if ([MOBILE_CONTAINER].includes(props.theme.containerSize)) {
      return css`
        width: 100%;
      `
    }
  }};
`
