import { LogoHp, LogoHpInstantInk } from '@veneer/core/dist/scripts/logos'
import React, { useEffect, useState } from 'react'
import { MOBILE_CONTAINER, useContainerSize } from '../../hooks'
import { StyledIconBox } from './styles'

export const IntantInkLogo = () => {
  const containerSize = useContainerSize()
  const [hpIconSize, setHpIconSize] = useState(30)
  const [instantInkIconSize, setInstantInkIconSize] = useState(36)

  useEffect(() => {
    if (containerSize === MOBILE_CONTAINER) {
      setHpIconSize(22)
      setInstantInkIconSize(26)
    } else {
      setHpIconSize(30)
      setInstantInkIconSize(36)
    }
  }, [containerSize])

  return (
    <StyledIconBox data-testid="instant-ink-logo">
      <LogoHp
        data-testid="hp-logo-icon"
        appearance="singlecolor"
        size={hpIconSize}
      />
      <LogoHpInstantInk
        data-testid="ii-logo-icon"
        appearance="multicolor"
        size={instantInkIconSize}
      />
    </StyledIconBox>
  )
}
