import { BillingForm } from '@jarvis/react-billing'
import React from 'react'
import { StyledModal } from './styles'
import { useFlags } from 'launchdarkly-react-client-sdk'

const IIBillingModal = ({
  iIBillingModal,
  handleOnCloseModal,
  country,
  language,
  authProvider,
  shell,
  handleOnSave,
  handleOnUpdate,
  isAgentSession,
  billingBaseURLProvider,
  enablePaypalInWebview,
  isNative,
  enableAllPaymentLayout,
  enableGPay,
  enableApplePay
}: any) => {
  const { pfEnableIiApplePay } = useFlags()
  const enableAllPaymentLayoutBilling = enableAllPaymentLayout
  const enableGPayBilling = enableGPay
  const enableIIApplePay = enableApplePay && pfEnableIiApplePay

  return (
    <>
      {iIBillingModal && (
        <StyledModal
          data-testid="modal"
          closeButton
          show={iIBillingModal}
          onClose={handleOnCloseModal}
          closeOnBlur={false}
        >
          <BillingForm
            accountIdentifier={'testingId'}
            country={country}
            language={language}
            baseURLProvider={billingBaseURLProvider}
            authProvider={authProvider}
            stack={shell?.stack}
            mockStratus={false}
            onSave={handleOnSave}
            onCancel={handleOnCloseModal}
            onUpdate={handleOnUpdate}
            virtualKeyboard={isAgentSession}
            nativeApp={isNative}
            enablePaypalInNativeApp={enablePaypalInWebview}
            enableGPay={enableGPayBilling}
            enableAllPaymentLayout={enableAllPaymentLayoutBilling}
            enableApplePay={enableIIApplePay}
          />
        </StyledModal>
      )}
    </>
  )
}

export default IIBillingModal
