import React, { useEffect, useState } from 'react'
import * as Styled from './styles'
import desktopBanner from './instant-ink-link-payment.png'
import tabletBanner from './instant-ink-link-payment-tablet.png'
import mobileBanner from './instant-ink-link-payment-mobile.png'
import {
  MOBILE_CONTAINER,
  TABLET_LANDSCAPE_CONTAINER,
  TABLET_PORTRAIT_CONTAINER,
  // useBillingFormState,
  useContainerSize,
  useGetText
} from '../../hooks'
import { ReturnToHpSmartButton } from '../../components/ReturnToHpSmartButton'
import { IntantInkLogo } from '../../components/InstantInkLogo'

export const LinkPaymentContent = () => {
  const containerSize = useContainerSize()
  const getText = useGetText()
  // const { appName } = useBillingFormState()
  const [bannerImage, setBannerImage] = useState('')

  useEffect(() => {
    if (containerSize === MOBILE_CONTAINER) {
      setBannerImage(mobileBanner)
    } else if (
      containerSize === TABLET_LANDSCAPE_CONTAINER ||
      containerSize === TABLET_PORTRAIT_CONTAINER
    ) {
      setBannerImage(tabletBanner)
    } else {
      setBannerImage(desktopBanner)
    }
  }, [containerSize])

  return (
    <Styled.LinkPaymentPageContainer>
      <Styled.Banner>
        <Styled.Image
          src={bannerImage}
          alt="Instant Ink customer"
          data-testid={`link-payment-banner-${containerSize}`}
        />
      </Styled.Banner>
      <Styled.CheckoutContainer>
        <Styled.ChekoutTitle>
          <IntantInkLogo />
          <h2>{getText('link_payment_page.sub_title')}</h2>
        </Styled.ChekoutTitle>
        <Styled.CheckoutContent>
          <Styled.CheckoutOptionsWrapper>
            {getText('link_payment_page.selection')}
            <Styled.CheckoutButtons>
              <Styled.MockedPaymentButton
                color="#253b80"
                backgroundColor="#ffc43a"
              >
                Paypal
              </Styled.MockedPaymentButton>
              <Styled.MockedPaymentButton backgroundColor="#000000">
                GPay
              </Styled.MockedPaymentButton>
            </Styled.CheckoutButtons>
          </Styled.CheckoutOptionsWrapper>
          <Styled.CheckoutOptionsWrapper>
            {getText('link_payment_page.card_option')}
            <ReturnToHpSmartButton /*appName={appName}*/ />
          </Styled.CheckoutOptionsWrapper>
        </Styled.CheckoutContent>
      </Styled.CheckoutContainer>
    </Styled.LinkPaymentPageContainer>
  )
}
