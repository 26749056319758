import React, { useState } from 'react'
import {
  BillingModalWindowCancelBtnClicked,
  BillingModalWindowContinueBtnClicked,
  BillingModalWindowDisplayed,
  EditPaymentLinkClicked,
  publishEvent
} from '@/utils/analytics'
import useCookies from '@/hooks/useCookie/useCookies'
import useGetText from '@/hooks/useGetText'
import SecuritySessionExpiredModal from '../../../shared/SecuritySessionExpiredModal'
import ManagePaymentButton from './ManagePaymentButton'
import IIBillingModal from '../IIBillingModal'
import useIIBillingFormActions from '../useIIBillingFormActions'

interface FooterProps {
  subscriptionIds: string[]
  country: string
  language: string
  authProvider: any
  shell: any
  instantInkSubscriptions: any
  forceRefresh: any
  forceTaxIdRefresh: any
  instantInkBillingAddressForceRefresh: any
  commonProps: any
}

const Footer = (props: FooterProps) => {
  const {
    subscriptionIds,
    country,
    language,
    authProvider,
    shell,
    instantInkSubscriptions,
    forceRefresh,
    forceTaxIdRefresh,
    instantInkBillingAddressForceRefresh,
    commonProps
  } = props
  const getText = useGetText('billing')
  const isAgentSession = useCookies('stratus-session-id')
  const isNative = window?.JWeb?.isNative

  const {
    setLocalNotificationList,
    localNotificationList,
    handleRefreshNotification,
    handleIIBillingModal,
    iIBillingModal,
    criticalScopeError,
    criticalScopeLoading,
    forceCriticalScope,
    sessionModal,
    handleSessionModal,
    settingsData
  } = commonProps

  const billingBaseURLProvider = async () =>
    `${process.env.BILLING_BASE_URL_PROVIDER}/instantink/v1/commerce`

  const enablePaypalInWebview = settingsData?.enablePaypalInWebview
  const enableAllPaymentLayout = settingsData?.enableAllPaymentLayout
  const enableGPay = settingsData?.enableGPay
  const enableApplePay = settingsData?.enableApplePay
  const editPaymentMethodDisabled = subscriptionIds?.length === 0
  const [customErr, setCustomErr] = useState(false)
  const { handleOnSave } = useIIBillingFormActions({
    handleIIBillingModal,
    getText,
    localNotificationList,
    setLocalNotificationList,
    handleRefreshNotification,
    setCustomErr,
    forceRefresh,
    forceTaxIdRefresh,
    instantInkBillingAddressForceRefresh
  })

  const editBillingClick = () => {
    forceCriticalScope()
    handleIIBillingModal()
    publishEvent(
      { ...EditPaymentLinkClicked, screenMode: 'InstantInk' },
      { actionParams: { subscriptionId: subscriptionIds.join('+') } }
    )
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenName: `Billing1`,
      screenMode: 'InstantInk'
    })
  }

  const handleOnCloseModal = () => {
    handleIIBillingModal()
    publishEvent({
      ...BillingModalWindowCancelBtnClicked,
      screenName: `Billing`,
      screenMode: 'InstantInk'
    })
  }
  const handleOnUpdate = () => {
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenName: `Billing1`,
      screenMode: 'InstantInk'
    })
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenName: `Billing2`,
      screenMode: 'InstantInk'
    })
  }

  return (
    <>
      {(customErr || (criticalScopeError && !criticalScopeLoading)) &&
      instantInkSubscriptions?.length > 0 ? (
        <SecuritySessionExpiredModal
          show={sessionModal}
          onClose={handleSessionModal}
        />
      ) : (
        <IIBillingModal
          iIBillingModal={iIBillingModal}
          handleOnCloseModal={handleOnCloseModal}
          country={country}
          language={language}
          billingBaseURLProvider={billingBaseURLProvider}
          authProvider={authProvider}
          shell={shell}
          handleOnSave={handleOnSave}
          handleOnUpdate={handleOnUpdate}
          isAgentSession={isAgentSession}
          enablePaypalInWebview={enablePaypalInWebview}
          isNative={isNative}
          enableAllPaymentLayout={enableAllPaymentLayout}
          enableGPay={enableGPay}
          enableApplePay={enableApplePay}
        />
      )}
      <ManagePaymentButton
        onClick={editBillingClick}
        isDisabled={editPaymentMethodDisabled}
        getText={getText}
      />
    </>
  )
}

export default Footer
