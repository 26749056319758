import React, { FC } from 'react'
import { StyledReturnButton } from './styles'

export type ReturnToHpSmartProps = {
  // appName?: string
}

export const ReturnToHpSmartButton: FC<
  ReturnToHpSmartProps
> = (/*{appName}*/) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // TODO: When integrating with backend retrieve the returnHpSmartUrl from backend and redirect to it
    event.persist()
    window.open('https://google.com', '_blank', 'noopener')
  }

  return (
    <StyledReturnButton
      data-testid="return-to-hp-smart-button"
      data-analyticsid="return-to-hp-smart-button"
      onClick={handleClick}
    >
      Return To HP Smart
    </StyledReturnButton>
  )
}
