import React, { useState } from 'react'
import {
  CCImageContainer,
  CCInfoText,
  StyledAddButtonContainer
} from './styles'
import Images from '@/assets/images'
import useGetText from '@/hooks/useGetText'
import { IconChevronRight } from '@veneer/core/dist/scripts/icons'
import {
  BillingModalWindowDisplayed,
  publishEvent,
  BillingModalWindowContinueBtnClicked,
  BillingModalWindowCancelBtnClicked
} from '@/utils/analytics'
import SecuritySessionExpiredModal from '../../../shared/SecuritySessionExpiredModal'
import useCookies from '@/hooks/useCookie/useCookies'
import IIBillingModal from '../IIBillingModal'
import useIIBillingFormActions from '../useIIBillingFormActions'
interface AddPaymentMethodProps {
  subscriptionIds: string[]
  instantInkSubscriptions: any
  country: string
  language: string
  authProvider: any
  shell: any
  commonProps: any
  forceRefresh: any
  forceTaxIdRefresh: any
  instantInkBillingAddressForceRefresh: any
}

const AddPaymentMethod = (props: AddPaymentMethodProps) => {
  const {
    subscriptionIds,
    instantInkSubscriptions,
    country,
    language,
    authProvider,
    shell,
    commonProps,
    forceRefresh,
    forceTaxIdRefresh,
    instantInkBillingAddressForceRefresh
  } = props
  const getText = useGetText('billing')

  const {
    setLocalNotificationList,
    localNotificationList,
    handleRefreshNotification,
    handleIIBillingModal,
    iIBillingModal,
    criticalScopeError,
    criticalScopeLoading,
    forceCriticalScope,
    sessionModal,
    handleSessionModal,
    settingsData
  } = commonProps

  const [customErr, setCustomErr] = useState(false)
  const isAgentSession = useCookies('stratus-session-id')
  const isNative = window?.JWeb?.isNative
  const enablePaypalInWebview = settingsData?.enablePaypalInWebview
  const enableAllPaymentLayout = settingsData?.enableAllPaymentLayout
  const enableGPay = settingsData?.enableGPay
  const enableApplePay = settingsData?.enableApplePay
  const addPaymentMethodDisabled = subscriptionIds.length === 0

  const billingBaseURLProvider = async () =>
    `${process.env.BILLING_BASE_URL_PROVIDER}/instantink/v1/commerce`

  const { handleOnSave } = useIIBillingFormActions({
    handleIIBillingModal,
    getText,
    localNotificationList,
    setLocalNotificationList,
    handleRefreshNotification,
    setCustomErr,
    forceRefresh,
    forceTaxIdRefresh,
    instantInkBillingAddressForceRefresh
  })

  const addBillingClick = async () => {
    forceCriticalScope()
    handleIIBillingModal()
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenMode: 'InstantInk',
      screenName: `Billing1`
    })
  }

  const handleOnCloseModal = () => {
    handleIIBillingModal()
    publishEvent({
      ...BillingModalWindowCancelBtnClicked,
      screenName: `Billing`,
      screenMode: 'InstantInk'
    })
  }
  const handleOnUpdate = () => {
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenMode: 'InstantInk',
      screenName: `Billing1`
    })
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenMode: 'InstantInk',
      screenName: `Billing2`
    })
  }

  const addPaymentMethodDetailsContent = (
    <>
      <CCImageContainer>
        <img
          data-testid="emptyPaymentIcon"
          src={Images.billing}
          alt={getText('addPayment')}
          style={{
            width: '30px',
            height: '25px'
          }}
        />
      </CCImageContainer>
      <CCInfoText>{getText('noBilling')}</CCInfoText>
      <StyledAddButtonContainer isDisabled={addPaymentMethodDisabled}>
        <a
          role="button"
          data-testid="addBillingButton"
          onClick={addBillingClick}
        >
          {getText('addPayment')}
          <IconChevronRight
            size={20}
            customStyle={{
              marginLeft: '5px',
              color: '#0278ab',
              verticalAlign: 'middle'
            }}
          />
        </a>
      </StyledAddButtonContainer>
    </>
  )

  return (
    <div className="addPaymentMethodContainer">
      {addPaymentMethodDetailsContent}

      {(customErr || (criticalScopeError && !criticalScopeLoading)) &&
      instantInkSubscriptions?.length > 0 ? (
        <SecuritySessionExpiredModal
          show={sessionModal}
          onClose={handleSessionModal}
        />
      ) : (
        <IIBillingModal
          iIBillingModal={iIBillingModal}
          country={country}
          language={language}
          billingBaseURLProvider={billingBaseURLProvider}
          authProvider={authProvider}
          mockStratus={false}
          handleOnSave={handleOnSave}
          handleOnCloseModal={handleOnCloseModal}
          handleOnUpdate={handleOnUpdate}
          shell={shell}
          isAgentSession={isAgentSession}
          isNative={isNative}
          enablePaypalInNativeApp={enablePaypalInWebview}
          enableAllPaymentLayout={enableAllPaymentLayout}
          enableGPay={enableGPay}
          enableApplePay={enableApplePay}
        />
      )}
    </div>
  )
}

export default AddPaymentMethod
