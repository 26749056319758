import { useCallback, useEffect, useState } from 'react'
import CriticalScopesClient from '../../services/CriticalScopesClient'
import { CriticalScopes } from '../../types/TaxpayerDetails'
import getAuthProvider from '../../helpers/getAuthProvider'

const useCriticalScopes = (invokeFlag: boolean) => {
  const authProvider = getAuthProvider()
  const [data, setData] = useState<CriticalScopes | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const getCriticalScopes = useCallback(async () => {
    setIsLoading(true)
    setError(false)

    const client = new CriticalScopesClient(authProvider)

    try {
      const result = await client.getCriticalScopes()
      setData(result)
      if (result?.remainingTime < 0) {
        setError(true)
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        setError(true)
      }
    } finally {
      setIsLoading(false)
    }
  }, [authProvider])

  useEffect(() => {
    if (invokeFlag) {
      getCriticalScopes()
    }
  }, [])

  return { data, error, isLoading, forceRefresh: getCriticalScopes }
}

export default useCriticalScopes
