import React, { useMemo } from 'react'
import ShippingBillingContainer from './ShippingBillingContainer'
import { ShippingBillingAnalyticsProvider } from '../../utils/ShippingBillingAnalyticsProvider/index'
import { getIIActiveSubscriptions } from '../../utils/getIIActiveSubscriptions'

const InitialComponent = (props) => {
  props.commonProps.notificationList =
    props.commonProps.commonNotificationData.notificationsList

  const { hasActiveSubscriptions } = getIIActiveSubscriptions(props.commonProps)

  const filteredNotificationList = useMemo(() => {
    if (!hasActiveSubscriptions) {
      return props.commonProps.notificationList.filter(
        (notification) =>
          notification.notificationID !== 'II_ExpiredNotification' &&
          notification.notificationID !== 'II_AboutToExpireNotification'
      )
    }
    return props.commonProps.notificationList
  }, [hasActiveSubscriptions, props.commonProps.notificationList])

  return (
    <>
      {(props?.commonProps?.isHPOneSubscriptionAvailable ||
        (props?.commonProps?.iInk?.iInkSubscriptionInfo
          ?.instantInkSubscriptions &&
          props?.commonProps?.iInk?.iInkSubscriptionInfo.instantInkSubscriptions
            .length > 0)) && (
        <ShippingBillingAnalyticsProvider
          commonProps={{
            ...props.commonProps,
            notificationList: filteredNotificationList
          }}
        >
          <ShippingBillingContainer
            commonProps={{
              ...props.commonProps,
              notificationList: filteredNotificationList
            }}
          />
        </ShippingBillingAnalyticsProvider>
      )}
    </>
  )
}

export default InitialComponent
