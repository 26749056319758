import React from 'react'
import CreditCard from '../CreditCard'
import PayPal from '../PayPal'
import { PaymentMethodType } from '@/types/PaymentMethodType'

interface BillingAddressProps {
  expirationStatus: string
  billingInfo: any
  instantInkBillingAddress: any
  hasActiveSubscriptions: boolean
  language?: string
}
const BillingAddress = (props: BillingAddressProps) => {
  const {
    expirationStatus,
    billingInfo,
    instantInkBillingAddress,
    hasActiveSubscriptions,
    language
  } = props

  const { countryCode, lastName, firstName, fullName } =
    instantInkBillingAddress || {}
  const isHKorTW = ['hk', 'tw'].includes(countryCode?.toLowerCase())
  const isZH = language?.toLowerCase() === 'zh'

  const formattedFullName =
    isHKorTW && isZH
      ? [lastName, firstName].filter(Boolean).join(' ')
      : fullName

  return (
    <div>
      {billingInfo?.paymentType === PaymentMethodType.pay_pal ? (
        <PayPal userName={formattedFullName} billingInfo={billingInfo} />
      ) : (
        <CreditCard
          userName={formattedFullName}
          billingInfo={billingInfo}
          expirationStatus={expirationStatus}
          hasActiveSubscriptions={hasActiveSubscriptions}
        />
      )}
    </div>
  )
}

export default BillingAddress
