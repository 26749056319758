import React from 'react'
import { useContainerSize } from '../../hooks'
import { ThemeProvider } from 'styled-components'
import { LinkPaymentContent } from './linkPaymentContent'

export const LinkPaymentContainer = () => {
  const containerSize = useContainerSize()

  return (
    <ThemeProvider theme={{ containerSize }}>
      <LinkPaymentContent />
    </ThemeProvider>
  )
}
