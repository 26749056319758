import React from 'react'
import {
  BillingModalWindowCancelBtnClicked,
  BillingModalWindowContinueBtnClicked,
  BillingModalWindowDisplayed,
  EditPaymentLinkClicked,
  publishEvent
} from '@/utils/analytics'
import useCookies from '@/hooks/useCookie/useCookies'
import useGetText from '@/hooks/useGetText'
import ManagePaymentButton from './ManagePaymentButton'
import BillingModal from './BillingModal'
import useBillingFormActions from './useBillingFormActions'

interface FooterProps {
  subscriptionFriendlyIds: string[]
  subscriptionId: string
  country: string
  language: string
  authProvider: any
  shell: any
  isDisabled: boolean
  commonProps: any
  subscriptionIndex: number
  forceRefresh?: () => void
}

const Footer = (props: FooterProps) => {
  const {
    subscriptionFriendlyIds,
    subscriptionId,
    country,
    language,
    authProvider,
    shell,
    isDisabled,
    commonProps,
    forceRefresh
  } = props

  const isAgentSession = useCookies('stratus-session-id')
  const getText = useGetText('billing')

  const {
    billingModal,
    handleBillingModal,
    setLocalNotificationList,
    localNotificationList,
    handleRefreshNotification
  } = commonProps

  const { handleOnSave } = useBillingFormActions({
    handleBillingModal,
    getText,
    localNotificationList,
    setLocalNotificationList,
    handleRefreshNotification,
    forceRefresh
  })

  const editBillingClick = () => {
    handleBillingModal()
    publishEvent(
      { ...EditPaymentLinkClicked, screenMode: 'HpOne' },
      { actionParams: { subscriptionId: subscriptionFriendlyIds.join('+') } }
    )
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenName: `Billing1`,
      screenMode: 'HpOne'
    })
  }

  const handleOnCloseModal = () => {
    handleBillingModal()
    publishEvent({
      ...BillingModalWindowCancelBtnClicked,
      screenName: `Billing1`,
      screenMode: 'HpOne'
    })
  }

  const handleOnUpdate = () => {
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenName: `Billing1`,
      screenMode: 'HpOne'
    })
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenName: `Billing2`,
      screenMode: 'HpOne'
    })
  }
  //country is hardcoded due to a payment issue
  console.log(country)
  return (
    <>
      <BillingModal
        billingModal={billingModal}
        handleOnCloseModal={handleOnCloseModal}
        subscriptionId={subscriptionId}
        country={'US'}
        language={language}
        authProvider={authProvider}
        shell={shell}
        handleOnSave={handleOnSave}
        handleOnUpdate={handleOnUpdate}
        isAgentSession={isAgentSession}
      />
      <ManagePaymentButton
        onClick={editBillingClick}
        isDisabled={isDisabled}
        getText={getText}
        data-testid="manage-payment-button"
      />
    </>
  )
}

export default Footer
