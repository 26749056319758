import Button from '@veneer/core/dist/scripts/button'
import styled, { css } from 'styled-components'
import {
  DESKTOP_CONTAINER,
  MOBILE_CONTAINER,
  TABLET_LANDSCAPE_CONTAINER,
  TABLET_PORTRAIT_CONTAINER
} from '../../hooks'

export const LinkPaymentPageContainer = styled.div`
  display: flex;

  ${(props) => {
    if (![DESKTOP_CONTAINER].includes(props.theme.containerSize)) {
      return css`
        flex-direction: column;
      `
    }
  }};
`

export const Banner = styled.picture`
  overflow: hidden;
  max-height: 100vh;
`

export const Image = styled.img`
  height: 100vh;

  ${(props) => {
    if (![DESKTOP_CONTAINER].includes(props.theme.containerSize)) {
      return css`
        width: 100%;
        height: 100%;
        display: block;
      `
    }
  }};
`

export const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 40px 105px;
  align-self: center;

  && h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #212121;
    margin: 0;
  }

  ${(props) => {
    if ([MOBILE_CONTAINER].includes(props.theme.containerSize)) {
      return css`
        padding: 24px 24px 0;
        box-sizing: border-box;
        width: 100%;

        && h2 {
          font-size: 18px;
        }
      `
    } else if (
      [TABLET_LANDSCAPE_CONTAINER, TABLET_PORTRAIT_CONTAINER].includes(
        props.theme.containerSize
      )
    ) {
      return css`
        padding: 48px 0 0 0;
        width: fit-content;
      `
    }
  }};
`
export const ChekoutTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CheckoutContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`

export const CheckoutOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 16px;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`

export const CheckoutButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;

  ${(props) => {
    if ([MOBILE_CONTAINER].includes(props.theme.containerSize)) {
      return css`
        flex-wrap: wrap;
      `
    }
  }};
`

const shouldForwardProp = (prop: string | number) =>
  prop !== 'backgroundColor' && prop !== 'color'

export const MockedPaymentButton = styled(Button).withConfig({
  shouldForwardProp
})<{ color?: string; backgroundColor?: string }>`
  width: 264px;

  && {
    color: ${({ color }) => color || '#ffffff'};
    background-color: ${({ backgroundColor }) => backgroundColor || '#0073a8'};
  }

  ${(props) => {
    if ([MOBILE_CONTAINER].includes(props.theme.containerSize)) {
      return css`
        width: 100%;
      `
    } else if (
      [TABLET_LANDSCAPE_CONTAINER, TABLET_PORTRAIT_CONTAINER].includes(
        props.theme.containerSize
      )
    ) {
      return css`
        width: 280px;
      `
    }
  }};
`
