import useAddressItemData from '@/hooks/useAddressItemData'
import useAppContext from '@/hooks/useAppContext'
import useGetAddressId from '@/hooks/useGetAddressId'
import useGetError from '@/hooks/useGetError'
import useGetText from '@/hooks/useGetText'
import useRootProps from '@/hooks/useRootProps'
import { updateAddressItem } from '@/services/AddressService'
import ShippingAddress from '@/types/ShippingAddress'
import Button from '@veneer/core/dist/scripts/button'
import Checkbox from '@veneer/core/dist/scripts/checkbox'

import WarningIcon from '@veneer/core/dist/scripts/icons/icon_warning_alt'
import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import * as Yup from 'yup'
import Countries from '../../assets/countries/countriesArray.json'
import States from '../../assets/states/statesArray.json'
import ShippingSelect from '../ShippingFormFields/Select'
import ShippingTextField from '../ShippingFormFields/TextField'
import {
  ActionsRow,
  ButtonContainer,
  FieldContainer,
  FormContainer,
  OptionsRow,
  StyledColumn,
  StyledError,
  StyledTitle
} from './styles'
import tokens from '@veneer/tokens'

const newFormValues: ShippingAddress = {
  resourceId: '',
  firstName: '',
  lastName: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  countryCode: 'US',
  phoneNumber: '',
  company: '',
  bisPrimary: false
}

const cleanAddress = (formValues: ShippingAddress) => {
  Object.keys(formValues).forEach((key) => {
    if (!(key in newFormValues)) {
      delete formValues[key]
    }
  })
  return formValues
}

let editFormValues: ShippingAddress = null

const ShippingAddressForm = () => {
  const getText = useGetText('shippingForm')
  const stateOption = States.states
  const countryOption = Countries.countries
  const msgRequired = getText('error.required')
  const msgMaxLength = getText('error.maxLength')
  const lastNameMsgMaxLength = getText('error.lastNameMaxLength')
  const msgMobileNumberNotValid = getText('error.mobileNumberError')
  const msgMobileNumberIsEmpty = getText('error.emptyMobileNumberError')
  const msgPostalCode = getText('error.postalCode')
  const msgInvalidCharactersError = getText('error.invalidCharactersError')
  const invalidPoBoxes = getText('error.invalidPoBoxes')
  const invalidOfficeBoxes = getText('error.invalidOfficeBoxes')
  const invalidMilitaryBoxes = getText('error.invalidMilitaryBoxes')
  const nameReg = /^[a-zA-Z][0-9a-zA-Z._'@ -]*$/
  const nameRegLength = /^.{0,80}$/
  const lastNameRegLength = /^.{0,79}$/
  const invalidCharacterReg = [
    /^(?!.*[`~<>;':$%&^*"/[\]|{}()=_+])/,
    /^(?!([ADF])PO(\s)?\b(A([AEP]))?$).+$/i // NOSONAR
  ]
  const PoBoxRegex =
    /* istanbul ignore next */
    /^(?!.*\b(P(ost|ostal)?([ .]*O(ffice)?)?([ .]*Box)?)\b(?=\.?\s*\d+)).*$/i // NOSONAR
  const OfficeBoxRegex = /^(?!.*\bOffice\s*Box\b.*\d*).*/i // NOSONAR
  const militaryAddressRegex = [
    /* istanbul ignore next */
    /^(?!.*P\s?\.?\s?S\s?\.?\s?C\s?\.?\s?(\d+\s?,?\s?)?Box\s?\d+).*$/i, // NOSONAR
    /^(?!.*\bUnit\s?\d+\s*,?\s*Box\s?\d+\b).*$/i, // NOSONAR
    /^(?!(Ship\s?\d+\s?Hull\s?\d+$))/i // NOSONAR
  ]
  const phoneRegExp = /^\s?(1[\s-]?)?(\d{3}|\(\d{3}\))[\s-]?\d{3}[\s-]?\d{4}$/gm

  const validationSchema = () => {
    return Yup.object({
      firstName: Yup.string()
        .required(msgRequired)
        .matches(nameReg, msgInvalidCharactersError)
        .matches(nameRegLength, msgMaxLength),
      lastName: Yup.string()
        .required(msgRequired)
        .matches(nameReg, msgInvalidCharactersError)
        .matches(lastNameRegLength, lastNameMsgMaxLength),
      address: Yup.string()
        .required(msgRequired)
        .matches(/^(?!.*[`~<>;':$%&^*"/[\]|{}()=+])/, msgInvalidCharactersError)
        .matches(PoBoxRegex, invalidPoBoxes)
        .matches(OfficeBoxRegex, invalidOfficeBoxes)
        .matches(militaryAddressRegex[0], invalidMilitaryBoxes)
        .matches(militaryAddressRegex[1], invalidMilitaryBoxes)
        .matches(militaryAddressRegex[2], invalidMilitaryBoxes),
      address2: Yup.string()
        .matches(/^(?!.*[`~<>;':$%&^*"/[\]|{}()=+])/, msgInvalidCharactersError)
        .matches(PoBoxRegex, invalidPoBoxes)
        .matches(OfficeBoxRegex, invalidOfficeBoxes)
        .matches(militaryAddressRegex[0], invalidMilitaryBoxes)
        .matches(militaryAddressRegex[1], invalidMilitaryBoxes)
        .matches(militaryAddressRegex[2], invalidMilitaryBoxes),
      city: Yup.string()
        .required(msgRequired)
        .matches(invalidCharacterReg[0], msgInvalidCharactersError)
        .matches(invalidCharacterReg[1], invalidMilitaryBoxes),
      state: Yup.string()
        .required(msgRequired)
        .matches(invalidCharacterReg[0], msgInvalidCharactersError),
      postalCode: Yup.string()
        .required(msgRequired)
        .matches(/^\d{5}(-\d{4})?$/, msgPostalCode),
      countryCode: Yup.string()
        .required(msgRequired)
        .matches(invalidCharacterReg[0], msgInvalidCharactersError),
      phoneNumber: Yup.string()
        .required(msgMobileNumberIsEmpty)
        .matches(phoneRegExp, msgMobileNumberNotValid)
    })
  }

  const initialValues: ShippingAddress = newFormValues
  const addressId = useGetAddressId()
  const addressItemData = useAddressItemData()

  editFormValues = addressItemData
    ? cleanAddress(addressItemData)
    : newFormValues

  const { state, dispatch } = useAppContext()
  const {
    subscriptionType,
    authProvider,
    onSave,
    onCancel,
    baseURLProvider,
    updateURLProvider,
    onOpen,
    paperEligibility
  } = useRootProps()

  useEffect(() => {
    if (onOpen) onOpen()
  }, [onOpen])

  const handleSubmit = async (address) => {
    await updateAddressItem(
      address,
      state,
      subscriptionType,
      authProvider,
      dispatch,
      baseURLProvider,
      updateURLProvider,
      onSave,
      false,
      paperEligibility
    )
  }

  const tempErrorMessage = useGetError()
  let errorMessage

  if (
    state.showSuggestAddressForm &&
    tempErrorMessage?.toUpperCase() === 'SHP0026STF'
  ) {
    errorMessage = getText('error.invalidZipCode', {
      defaultValue:
        'The zip code is not correct for the provided address. Please check and try again.'
    })
  } else if (tempErrorMessage) {
    errorMessage = getText('error.' + tempErrorMessage)
    if (!errorMessage || errorMessage.includes('undefined') > 0)
      errorMessage = tempErrorMessage
  }
  const errorContent = errorMessage && (
    <StyledError>
      <WarningIcon size={36} color={'colorOrange6'} />
      <div data-testid="errormessage">{errorMessage}</div>
    </StyledError>
  )
  const formkitForm = (
    <Formik
      enableReinitialize={true}
      initialValues={addressId ? editFormValues : initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
    >
      {(formik) => {
        const err = formik.errors
        const enableSaveBtn = addressId
          ? formik.isValid && formik.touched
          : formik.isValid && formik.dirty
        return (
          <Form>
            <StyledColumn
              gap={tokens.layout.size4}
              marginBottom={tokens.layout.size4}
            >
              <FieldContainer>
                <ShippingTextField
                  id="firstName"
                  name="firstName"
                  label={getText('firstName')}
                  value={formik?.values?.firstName || ''}
                  errorMessage={err?.firstName?.toString()}
                  required
                />
              </FieldContainer>

              <FieldContainer>
                <ShippingTextField
                  id="lastName"
                  name="lastName"
                  label={getText('lastName')}
                  value={formik?.values?.lastName || ''}
                  errorMessage={err?.lastName?.toString()}
                  required
                />
              </FieldContainer>
            </StyledColumn>
            <StyledColumn
              gap={tokens.layout.size4}
              marginBottom={tokens.layout.size4}
            >
              <FieldContainer>
                <ShippingTextField
                  id="address"
                  name="address"
                  label={getText('address')}
                  value={formik?.values?.address || ''}
                  errorMessage={err?.address?.toString()}
                  required
                />
              </FieldContainer>

              <FieldContainer>
                <ShippingTextField
                  id="address2"
                  name="address2"
                  label={getText('addressInfo', {
                    defaultValue: 'Apt, suite, floor, etc. (Optional)'
                  })}
                  value={formik?.values?.address2 || ''}
                  errorMessage={err?.address2?.toString()}
                />
              </FieldContainer>
            </StyledColumn>
            <StyledColumn
              gap={tokens.layout.size4}
              marginBottom={tokens.layout.size4}
            >
              <FieldContainer>
                <ShippingTextField
                  id="city"
                  name="city"
                  label={getText('city')}
                  value={formik?.values?.city || ''}
                  errorMessage={err?.city?.toString()}
                  required
                />
              </FieldContainer>
              <FieldContainer>
                <ShippingSelect
                  id="state"
                  inputName="state"
                  label={getText('state')}
                  options={stateOption}
                  clearIcon={false}
                  value={[formik?.values?.state || '']}
                  errorMessage={err?.state?.toString()}
                  required
                />
              </FieldContainer>
            </StyledColumn>
            <StyledColumn
              gap={tokens.layout.size4}
              marginBottom={tokens.layout.size4}
            >
              <FieldContainer>
                <ShippingTextField
                  id="postalCode"
                  name="postalCode"
                  label={getText('postalCode')}
                  value={formik?.values?.postalCode || ''}
                  errorMessage={err?.postalCode?.toString()}
                  required
                />
              </FieldContainer>
              <FieldContainer>
                <ShippingSelect
                  id="countryCode"
                  inputName="countryCode"
                  label={getText('country')}
                  options={countryOption}
                  clearIcon={false}
                  errorMessage=""
                  disabled={true}
                  required
                />
              </FieldContainer>
            </StyledColumn>
            <StyledColumn
              gap={tokens.layout.size4}
              marginBottom={tokens.layout.size4}
            >
              <FieldContainer>
                <ShippingTextField
                  id="phoneNumber"
                  name="phoneNumber"
                  label={getText('mobileNumber')}
                  value={formik?.values?.phoneNumber || ''}
                  errorMessage={
                    err?.phoneNumber && formik?.touched
                      ? (err?.phoneNumber as string)
                      : null
                  }
                  required
                />
              </FieldContainer>
              <FieldContainer>
                <ShippingTextField
                  id="company"
                  name="company"
                  label={getText('company')}
                  value={formik?.values?.company || ''}
                />
              </FieldContainer>
            </StyledColumn>

            <OptionsRow>
              <Checkbox
                id="bisPrimary"
                name="bisPrimary"
                label={getText('makeDefaultAddress')}
                onChange={formik?.handleChange}
                checked={formik?.values?.bisPrimary || false}
                data-testid="bisPrimary-test-id"
              />
            </OptionsRow>

            <ActionsRow>
              <ButtonContainer>
                <Button
                  data-testid="cancel-button"
                  className="button"
                  appearance="secondary"
                  onClick={onCancel}
                  key="cancel-button"
                >
                  {getText('cancel')}
                </Button>
              </ButtonContainer>
              <ButtonContainer>
                <Button
                  data-testid="save-button"
                  type="submit"
                  className="button"
                  appearance="primary"
                  disabled={!enableSaveBtn}
                  key="save-button"
                >
                  {getText('save')}
                </Button>
              </ButtonContainer>
            </ActionsRow>
          </Form>
        )
      }}
    </Formik>
  )

  return (
    <FormContainer>
      <StyledTitle>{getText('header')}</StyledTitle>
      {errorContent}
      {formkitForm}
    </FormContainer>
  )
}

export default ShippingAddressForm
