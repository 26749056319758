import React from 'react'
import { BillingCard, CardWrapper } from './styles'
import Header from './Header'
import Footer from './Footer'
import getLocalization from '@/helpers/getLocalization'
import getCardBorderColor from '@/helpers/CardBorderColor/cardBorderColor'
import getAuthProvider from '@/helpers/getAuthProvider'
import useShellContext from '@/hooks/useShellContext'
import HandleTaxData from './HandleTaxData'
import useTaxData from '@/hooks/useTaxData'
import useBillingInfo from '@/hooks/useBillingInfo'
import useInstantInkBillingAddress from '@/hooks/useInstantInkBillingAddress'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'

import { instantInkStates, instantInkState } from '@/types/instantInkStates'
import BillingAddress from './BillingAddress'
import AddPaymentMethod from './AddPaymentMethod'
import { determineBillingVisibility } from '@/helpers/determineBillingVisibility'
import { getExpirationStatus } from '@/helpers/getExpirationStatus'
import { StyledLoader } from '../HPAIPBilling/styles'
import { getIIActiveSubscriptions } from '../../../../utils/getIIActiveSubscriptions'

interface InstantInkBillingProps {
  commonProps: any
}

const InstantInkBilling = ({ commonProps }: InstantInkBillingProps) => {
  const shell = useShellContext()
  const {
    config: { show },
    data: taxData,
    isLoading: loadingTaxData,
    forceRefresh: forceTaxIdRefresh
  } = useTaxData()

  const { data: billingInfo, forceRefresh, loading } = useBillingInfo()
  const {
    data: instantInkBillingAddress,
    forceRefresh: instantInkBillingAddressForceRefresh
  } = useInstantInkBillingAddress()
  const { hasActiveSubscriptions } = getIIActiveSubscriptions(commonProps)

  const instantInkSubscriptions =
    commonProps?.iInk?.iInkSubscriptionInfo?.instantInkSubscriptions

  const editTaxId = !!instantInkSubscriptions?.find(({ state }) =>
    instantInkState.includes(state as instantInkStates)
  )
  const authProvider = getAuthProvider()
  const accountData = commonProps?.accountData
  const expirationStatus =
    commonProps?.commonNotificationData?.instantInkDeliquencyStatus

  const expirationDate = billingInfo?.creditCard?.displayExpirationDate
  const expirationStatusinfo = getExpirationStatus(expirationDate)
  const { language } = getLocalization()
  const country = accountData.regionId || process.env.DEFAULT_COUNTRY
  const localeLanguage = language || process.env.DEFAULT_LANGUAGE

  const activeSubscriptions = instantInkSubscriptions?.filter(
    (subscription) => subscription.state !== instantInkStates.OBSOLETE
  )
  const subscriptionIds = activeSubscriptions?.map(
    (item) => item.accountIdentifier
  )

  const borderColor = hasActiveSubscriptions
    ? getCardBorderColor(expirationStatusinfo || expirationStatus)
    : ''

  const { showIICreditCard, showPayPal, showDirectDebit, isAddPayment } =
    determineBillingVisibility(billingInfo, instantInkSubscriptions)

  const renderIIBillingInfo = () => (
    <CardWrapper borderColor={borderColor}>
      <div>
        <div>
          <BillingCard>
            <div>
              <Header subscriptionId={subscriptionIds} />
              {billingInfo?.paymentType &&
                (showIICreditCard || showPayPal || showDirectDebit) && (
                  <div>
                    <BillingAddress
                      expirationStatus={expirationStatus}
                      billingInfo={billingInfo}
                      instantInkBillingAddress={instantInkBillingAddress}
                      hasActiveSubscriptions={hasActiveSubscriptions}
                      language={localeLanguage}
                    />
                    <Footer
                      authProvider={authProvider}
                      country={country}
                      language={localeLanguage}
                      shell={shell}
                      subscriptionIds={subscriptionIds}
                      instantInkSubscriptions={instantInkSubscriptions}
                      forceRefresh={forceRefresh}
                      forceTaxIdRefresh={forceTaxIdRefresh}
                      instantInkBillingAddressForceRefresh={
                        instantInkBillingAddressForceRefresh
                      }
                      commonProps={commonProps}
                    />
                  </div>
                )}
              {!showIICreditCard && !showPayPal && isAddPayment && (
                <AddPaymentMethod
                  subscriptionIds={subscriptionIds}
                  instantInkSubscriptions={instantInkSubscriptions}
                  country={country}
                  language={localeLanguage}
                  authProvider={authProvider}
                  shell={shell}
                  commonProps={commonProps}
                  forceRefresh={forceRefresh}
                  forceTaxIdRefresh={forceTaxIdRefresh}
                  instantInkBillingAddressForceRefresh={
                    instantInkBillingAddressForceRefresh
                  }
                />
              )}
            </div>
          </BillingCard>
        </div>
        {show && (
          <HandleTaxData
            taxId={taxData?.taxId}
            nonProfitTaxId={taxData?.nonProfitTaxId}
            allowEditingTaxId={taxData?.allowEditingTaxId}
            forceRefresh={forceTaxIdRefresh}
            editTaxId={editTaxId}
            instantInkSubscriptionInfo={instantInkSubscriptions}
            country={country}
            language={localeLanguage}
            shell={shell}
            commonProps={commonProps}
            loadingTaxData={loadingTaxData}
          />
        )}
      </div>
    </CardWrapper>
  )

  const renderContent = () => {
    if (loading) {
      return (
        <CardWrapper>
          <BillingCard>
            <StyledLoader>
              <LoadingHandler loading={loading} />
            </StyledLoader>
          </BillingCard>
        </CardWrapper>
      )
    }

    return renderIIBillingInfo()
  }

  return renderContent()
}

export default InstantInkBilling
