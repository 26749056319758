import styled from 'styled-components'
import tokens from '@veneer/tokens'
import { IconChevronRight } from '@veneer/core/dist/scripts/icons'

export const StyledButtonContainer = styled.div`
  && {
    font-weight: 400;
    padding: ${tokens.layout.size1} 0 0 0;
    font-family: ${tokens.typography.family0};
    font-size: 14px;
    a {
      cursor: pointer;
      color: ${tokens.color.hpBlue7};
    }
  }
`
export const StyledAddButtonContainer = styled.div<{ isDisabled?: boolean }>`
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.isDisabled ? 'none' : 'default')};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  font-size: ${tokens.typography.size1};
  line-height: ${tokens.typography.lineHeight2};
  font-weight: 400;
    && {
    a {
      cursor: pointer;
      color: ${(props) =>
        props.isDisabled
          ? tokens.color.highContrastGray
          : tokens.color.hpBlue7};
  }
    }
    }
`
export const StyledIconChevronRight = styled(IconChevronRight)`
  margin-left: 5px;
  vertical-align: middle;
  & path {
    fill: ${tokens.color.hpBlue7};
  }
`
export const StyledContent = styled.div<{ isDisabled?: boolean }>`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size2};
    line-height: ${tokens.typography.lineHeight3};
    font-weight: 400;
    color: ${(props) =>
      props.isDisabled ? tokens.color.highContrastGray : tokens.color.gray9};
    span {
      word-break: break-word;
    }
  }
`
export const StyledEmptyContent = styled.div<{ isDisabled?: boolean }>`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight3};
    font-weight: 400;
    color: ${(props) =>
      props.isDisabled ? tokens.color.highContrastGray : tokens.color.gray9};
    span {
      word-break: break-word;
    }
  }
`
