import React from 'react'
import { BillingFormProvider } from '../../components/BillingForm/billingFormProvider'
import { GlobalStyle } from '../../styles/styles'
import { Stack } from '@jarvis/web-stratus-client'
import { LinkPaymentContainer } from './linkPaymentContainer'
import { JarvisAuthProvider } from '@jarvis/web-http'

export type LinkPaymentProps = {
  /** Country */
  country: string

  /** Language */
  language: string

  /**
   * authentication provider object that must include the getAccessToken function
   */
  authProvider: JarvisAuthProvider

  /**
   * enum of (dev,pie,stage,prod) used for stratus web calls
   */
  stack: Stack

  /**
   * app name when running inside a native app
   * */
  appName?: string

  /**
   * app name when running inside a native app
   * */
  oneTimeToken?: string
}

export const LinkPayment = (props: LinkPaymentProps) => {
  return (
    <>
      <GlobalStyle />
      <BillingFormProvider {...props}>
        <LinkPaymentContainer />
      </BillingFormProvider>
    </>
  )
}
