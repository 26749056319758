import React from 'react'
import {
  StyledIconContainer,
  StyledContent,
  StyledTitleContent
} from './styles'
import Images from '@/assets/images'
import getLocalization from '@/helpers/getLocalization'

interface SectionProps {
  instantInkAddress: any
  countryCode?: string
}

const Section = (props: SectionProps) => {
  const { instantInkAddress, countryCode } = props
  const shippingAddressInfo = instantInkAddress
  const { language } = getLocalization()
  const currLanguage =
    language || `${process.env.DEFAULT_LANGUAGE}_${countryCode}`

  const isHKorTW = ['hk', 'tw'].includes(countryCode?.toLowerCase())
  const isZH = currLanguage?.toLowerCase() === 'zh'

  const fullName =
    isHKorTW && isZH
      ? [shippingAddressInfo?.lastName, shippingAddressInfo?.firstName]
          .filter(Boolean)
          .join(' ')
      : shippingAddressInfo?.fullName

  return (
    <div>
      <StyledIconContainer>
        <img src={Images.shipping} alt="title icon" />
      </StyledIconContainer>
      <div>
        <StyledTitleContent>{fullName}</StyledTitleContent>
        <StyledContent>
          {shippingAddressInfo?.address} <br />
          {shippingAddressInfo?.address2 ? (
            <span>{shippingAddressInfo?.address2}</span>
          ) : (
            <></>
          )}
        </StyledContent>
        <StyledContent>
          <span>
            {shippingAddressInfo?.city}, {shippingAddressInfo?.state}{' '}
            {shippingAddressInfo?.postalCode}
          </span>
        </StyledContent>
      </div>
    </div>
  )
}

export default Section
