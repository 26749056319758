import React from 'react'
import HPAIPShipping from './HPAIPShipping'
import InstantInkShipping from './InstantInkShipping'
import {
  ShippingAddressContainer,
  ShippingSectionContainer,
  StyledTextContainer,
  StyledTitle
} from './styles'
import useGetText from '@/hooks/useGetText'

interface ShippingProps {
  commonProps: any
}

const AddressHeader = ({
  getText
}: {
  getText: (key: string, options?: any) => string
}) => (
  <>
    <StyledTitle role="heading" aria-level="2">
      {getText('yourAddress', { defaultValue: 'Your shipping addresses' })}
    </StyledTitle>
    <StyledTextContainer>
      {getText('withShippingInfoMsg', {
        defaultValue:
          'The shipping information below is on file for your HP account. Keep your shipping address up to date so we always know where to reach you or send subscription ink supplies.'
      })}
    </StyledTextContainer>
  </>
)

const Shipping = (props: ShippingProps) => {
  const { commonProps } = props
  const getText = useGetText('shipping')

  const hasInstantInkSubscription =
    commonProps?.iInk?.iInkSubscriptionInfo?.instantInkSubscriptions?.length > 0

  return (
    <ShippingSectionContainer>
      {(commonProps?.isHPOneSubscriptionAvailable ||
        hasInstantInkSubscription) && <AddressHeader getText={getText} />}
      <ShippingAddressContainer>
        {commonProps?.isHPOneSubscriptionAvailable && (
          <HPAIPShipping commonProps={commonProps} />
        )}
        {hasInstantInkSubscription && (
          <InstantInkShipping commonProps={commonProps} />
        )}
      </ShippingAddressContainer>
    </ShippingSectionContainer>
  )
}

export default Shipping
