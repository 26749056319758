import React from 'react'
import getPaymentCardInfo from '@/helpers/getPaymentCardInfo'
import { IconInfo } from '@veneer/core/dist/scripts/icons'
import { EDelinquencyNotification } from '@/types/instantInkStates'
import {
  PaymentIconInfo,
  CCImageContainer,
  CCInfoText,
  GPayPayment
} from './styles'
import { PaymentMethodType } from '@/types/PaymentMethodType'
import useGetText from '@/hooks/useGetText'
import Images from '@/assets/images'
import { useFlags } from 'launchdarkly-react-client-sdk'

const CreditCard = ({
  userName,
  billingInfo,
  expirationStatus,
  hasActiveSubscriptions
}) => {
  const getText = useGetText('billing')

  const { pfEnableIiApplePay } = useFlags()

  const cardInfo =
    billingInfo?.paymentType === PaymentMethodType.direct_debit
      ? 'directDebit'
      : billingInfo?.creditCard
  const creditCardInfo = getPaymentCardInfo(cardInfo)

  let expireInfo

  if (
    expirationStatus === EDelinquencyNotification.ABOUT_TO_EXPIRE &&
    hasActiveSubscriptions
  ) {
    expireInfo = (
      <PaymentIconInfo expirationStatus={expirationStatus}>
        <IconInfo className="iconInfo" size={15} /> {getText('expiringSoon')}{' '}
        {billingInfo?.creditCard?.displayExpirationDate}
      </PaymentIconInfo>
    )
  } else if (
    expirationStatus === EDelinquencyNotification.EXPIRED &&
    hasActiveSubscriptions
  ) {
    expireInfo = (
      <PaymentIconInfo expirationStatus={expirationStatus}>
        <IconInfo className="iconInfo" size={15} /> {getText('expired')}
      </PaymentIconInfo>
    )
  } else {
    expireInfo = (
      <>
        {getText('expires')} {billingInfo?.creditCard?.displayExpirationDate}
      </>
    )
  }

  const gPayAvailable = billingInfo?.creditCard?.cardSource === 'GPay'

  const applePayAvailable =
    billingInfo?.creditCard?.cardSource === 'APPLE_PAY' && pfEnableIiApplePay

  const cardSource = {
    GPay: Images.gPay,
    APPLE_PAY: Images.applePay
  }

  return (
    <>
      <CCImageContainer>
        <img
          src={creditCardInfo?.cardImage}
          alt={billingInfo?.creditCard?.cardTypeKey}
        />
      </CCImageContainer>
      {billingInfo?.paymentType === PaymentMethodType.direct_debit ? (
        <CCInfoText>{billingInfo?.directDebit?.bankRedactedIban}</CCInfoText>
      ) : (
        <>
          <CCInfoText>{userName}</CCInfoText>
          <CCInfoText>{billingInfo?.creditCard?.cardNumber}</CCInfoText>
          <CCInfoText>
            <>{expireInfo}</>
          </CCInfoText>
          {(gPayAvailable || applePayAvailable) && (
            <CCInfoText>
              <GPayPayment>
                <div>via</div>
                <img
                  src={cardSource[billingInfo?.creditCard?.cardSource]}
                  alt={billingInfo?.creditCard?.cardSource}
                />
              </GPayPayment>
            </CCInfoText>
          )}
        </>
      )}
    </>
  )
}

export default CreditCard
