import { instantInkStates } from './instantInkStates'

export const getIIActiveSubscriptions = (commonProps) => {
  const instantInkSubscriptions =
    commonProps?.iInk?.iInkSubscriptionInfo?.instantInkSubscriptions || []

  const hasActiveSubscriptions = instantInkSubscriptions.some(
    (subscription) => subscription.state !== instantInkStates.OBSOLETE
  )

  return { instantInkSubscriptions, hasActiveSubscriptions }
}
